import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { Flex, PageTemplate, QuestionFilter } from "../../../components";

import { AuthContext } from "../../../contexts/auth";
import { ERoutesPath } from "../../../routes";
import {
  EAvailableFields,
  TFilterFormValues,
} from "../../../components/organisms/QuestionFilter/types";
import { END_POINTS } from "../../../services/routesPath";
import api from "../../../services/api";

export const SimulateNewPage = () => {
  const navigate = useNavigate();

  const { isPaidActive, isUserLogged, user } = useContext(AuthContext);
  const idAccount = user.id_user;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!isUserLogged || !isPaidActive) {
      navigate(ERoutesPath.ROOT);
    }
  }, []);

  const FILTER_OPTIONS = [
    EAvailableFields.COURSES,
    EAvailableFields.BANKS,
    EAvailableFields.YEARS,
    EAvailableFields.AMOUNT,
    EAvailableFields.TIME,
    EAvailableFields.SIMULATE_NAME,
  ];
  const REQUIRED_FIELDS = [
    EAvailableFields.AMOUNT,
    EAvailableFields.TIME,
    EAvailableFields.SIMULATE_NAME,
  ];

  const handleFilter = async (data: TFilterFormValues) => {
    try {
      setIsLoading(true);
      const newSimulate = await api.post(
        `${END_POINTS.simulates}/${idAccount}`,
        data
      );
    } finally {
      setIsLoading(false);
      navigate(ERoutesPath.SIMULADOS);
    }
  };

  const newSimulateBreadcrumb = [
    { label: "Página inicial", route: ERoutesPath.ROOT },
    {
      label: "Simulados",
      route: ERoutesPath.SIMULADOS,
    },
    { label: "Novo" },
  ];

  return (
    <PageTemplate
      title="Novo simulado"
      breadcrumbsItems={newSimulateBreadcrumb}
    >
      <Flex direction="column" gap={16}>
        <QuestionFilter
          fieldToShow={FILTER_OPTIONS}
          requiredFields={REQUIRED_FIELDS}
          onSubmit={handleFilter}
          isSimulate
          isLoadingFilter={isLoading}
          amountAvailable={[
            "15",
            "20",
            "30",
            "40",
            "50",
            "60",
            "75",
            "80",
            "90",
            "100",
          ]}
        />
      </Flex>
    </PageTemplate>
  );
};
