import { useNavigate } from "react-router-dom-v5-compat";
import { ERoutesPath } from "../../../routes";
import { Button, Flex, Typography, Tag } from "../../../components";
import { CircularProgress } from "@material-ui/core";

import { useContext, useEffect, useState } from "react";
import api, { END_POINTS } from "../../../services/api";
import { AuthContext } from "../../../contexts/auth";
import { formatDateDayHours } from "../../../utils/date";
import {
  deleteBorderIcon,
  playBorderIcon,
  trophyBorderIcon,
} from "../../../assets";

import { ESimulateStatus, TSimulate } from "../types";

import * as S from "./styles";
import { ConfirmDeleteModal } from "./ConfirmDelete";

export const SimulatesList = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [simulates, setSimulates] = useState<Array<TSimulate>>([]);
  const [confirmDelete, setConfirmDelete] = useState<number>();

  const idAccount = user.id_user;

  const handleNewTest = () => {
    navigate(ERoutesPath.SIMULADOS_NOVO);
  };

  const handleStartContinue = (simulateId: number) => {
    navigate(
      ERoutesPath.SIMULADO_RESPONDER.replace(":id", simulateId.toString())
    );
  };

  const getSimulates = async () => {
    try {
      setIsLoading(true);
      const simulateData = await api.get<Array<TSimulate>>(
        `${END_POINTS.simulates}/${idAccount}`
      );

      setSimulates(simulateData.data);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (simulateId: number) => {
    setConfirmDelete(simulateId);
  };

  const handleFeedback = async (simulateId: number) => {
    navigate(ERoutesPath.SIMULADO_RESUMO.replace(":id", simulateId.toString()));
  };

  useEffect(() => {
    getSimulates();
  }, []);

  return (
    <>
      <Typography color="#6E6893" family="Montserrat" size={20}>
        Meus simulados
      </Typography>

      <Flex direction="column" width="100%" gap={8}>
        {isLoading ? (
          <Flex justifyContent="center">
            <CircularProgress />
          </Flex>
        ) : (
          <>
            <Flex
              justifyContent="space-between"
              style={{
                backgroundColor: "#FFFFFF",
                padding: "16px",
                borderRadius: "8px",
              }}
            >
              <div></div>

              <Button size="small" onClick={handleNewTest}>
                Novo Simulado
              </Button>
            </Flex>

            <Flex direction="column" gap={8}>
              {simulates.map((simulate) => (
                <S.CardContainer>
                  <Flex direction="column">
                    <Typography size={12} color="#6e6893" weight={500}>
                      Simulado
                    </Typography>
                    <Typography size={14}>{simulate.name}</Typography>
                  </Flex>

                  <Flex direction="column">
                    <Typography size={12} color="#6e6893" weight={500}>
                      Criado em
                    </Typography>
                    <Typography size={14}>
                      {formatDateDayHours(simulate.created_at)}
                    </Typography>
                  </Flex>

                  <Flex direction="column">
                    <Typography size={12} color="#6e6893" weight={500}>
                      Última atualização
                    </Typography>
                    <Typography size={14}>
                      {formatDateDayHours(simulate.updated_at)}
                    </Typography>
                  </Flex>

                  <Flex direction="column">
                    <Typography size={12} color="#6e6893" weight={500}>
                      Andamento
                    </Typography>
                    <div>
                      {simulate.status === ESimulateStatus.FINISHED && (
                        <Tag type="SUCCESS" text="Concluído" />
                      )}
                      {simulate.status === ESimulateStatus.CREATED && (
                        <Tag type="ERROR" text="Não iniciado" />
                      )}
                      {simulate.status === ESimulateStatus.STARTED && (
                        <Tag type="WARNING" text="Iniciado" />
                      )}
                      {simulate.status === ESimulateStatus.PAUSED && (
                        <Tag type="INFO" text="Pausado" />
                      )}
                    </div>
                  </Flex>

                  <S.ActionContainer gap={16} justifyContent="flex-end">
                    {simulate.status === ESimulateStatus.FINISHED ? (
                      <Flex
                        direction="row"
                        alignItems="center"
                        gap={4}
                        onClick={() => handleFeedback(simulate.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={trophyBorderIcon} height={20} />
                        <Typography size={14} color="#525252">
                          Resultado
                        </Typography>
                      </Flex>
                    ) : (
                      <Flex
                        direction="row"
                        alignItems="center"
                        gap={4}
                        onClick={() => handleStartContinue(simulate.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={playBorderIcon} height={20} />
                        <Typography size={14} color="#525252">
                          Continuar
                        </Typography>
                      </Flex>
                    )}

                    <Flex
                      direction="row"
                      alignItems="center"
                      gap={4}
                      onClick={() => handleDelete(simulate.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <img src={deleteBorderIcon} height={20} />
                      <Typography size={14} color="#525252">
                        Excluir
                      </Typography>
                    </Flex>
                  </S.ActionContainer>
                </S.CardContainer>
              ))}
            </Flex>
          </>
        )}

        {confirmDelete && (
          <ConfirmDeleteModal
            modalDismiss={() => setConfirmDelete(undefined)}
            simulateId={confirmDelete}
            onCallback={getSimulates}
          />
        )}
      </Flex>
    </>
  );
};
