import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

import { Flex, PageTemplate, Question, Timer } from "../../../components";
import { CircularProgress } from "@material-ui/core";
import { AuthContext } from "../../../contexts/auth";
import { ERoutesPath } from "../../../routes";
import { TQuestion } from "../../../contexts/question";

import api, { END_POINTS } from "../../../services/api";
import { QuestionMenu } from "./QuestionMenu";

import { TChangeStatusResponse, TSelectedQuestionData } from "./types";
import { ESimulateStatus, TSimulate } from "../types";
import * as S from "./styles";

export const SimulateAnswerPage = () => {
  const navigate = useNavigate();
  const questionRefs = useRef<(HTMLDivElement | null)[]>([]);

  const { isPaidActive, isUserLogged, user } = useContext(AuthContext);
  const { id: simulateId } = useParams();
  const idAccount = user.id_user;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedQuestions, setSelectedQuestions] =
    useState<TSelectedQuestionData>({});
  const [questions, setQuestions] = useState<Array<TQuestion>>();
  const [simulateData, setSimulateData] = useState<TSimulate>();

  const isInitialized = useRef(false);

  const handleChangeStatus = async (status: ESimulateStatus) => {
    try {
      const body = {
        id_account: idAccount,
        id_simulate: Number(simulateId),
        status: status,
      };

      const changeStatusResponse = await api.post<TChangeStatusResponse>(
        END_POINTS.simulateChangeStatus,
        body
      );

      if (changeStatusResponse.status === 200)
        return changeStatusResponse.data.data;
    } catch {}
  };

  const handleResponseQuestion = async (questionId: number, answer: string) => {
    try {
      const body = {
        id_account: idAccount,
        id_simulate: Number(simulateId),
        id_question: questionId,
        answer: answer,
      };

      await api.post(END_POINTS.simulateResponseQuestion, body);
    } catch (e) {
      // validar se esta pausado e retornar para "MAIN"
      return new Error(e);
    }
  };

  const initializeSelectedQuestions = (questions: Array<TQuestion>) => {
    const initialSelected: TSelectedQuestionData = {};
    questions.forEach((question, index) => {
      initialSelected[index] = {
        questionId: question.id,
        alternative: question.selected_answered ?? "",
      };
    });

    setSelectedQuestions(initialSelected);
  };

  const isAllAlternativesFilled = useMemo(() => {
    return (
      selectedQuestions &&
      Object.values(selectedQuestions).every((q) => q.alternative.trim() !== "")
    );
  }, [selectedQuestions]);

  const practiceTestBreadcrumb = [
    { label: "Página inicial", route: ERoutesPath.ROOT },
    {
      label: "Simulados",
      route: ERoutesPath.SIMULADOS,
    },
    { label: "Responder" },
  ];

  const scrollToQuestion = (index: number) => {
    const questionElement = questionRefs.current[index];
    if (questionElement) {
      const headerOffset = 100;
      const elementPosition = questionElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const handleSelectedQuestion = (
    index: number,
    alternative: string,
    questionId: number,
    action: "ADD" | "REMOVE"
  ) => {
    handleResponseQuestion(questionId, action === "REMOVE" ? "" : alternative);

    setSelectedQuestions((prev) => ({
      ...prev,
      [index]: {
        questionId: questionId,
        alternative: action === "ADD" ? alternative : "",
      },
    }));
  };

  const handlePauseSimulate = () => {
    handleChangeStatus(ESimulateStatus.PAUSED);
    navigate(ERoutesPath.SIMULADOS);
  };

  const handleFinishSimulate = () => {
    handleChangeStatus(ESimulateStatus.FINISHED);
    if (simulateId)
      navigate(
        ERoutesPath.SIMULADO_RESUMO.replace(":id", simulateId.toString())
      );
  };

  useEffect(() => {
    if (isInitialized.current) return;
    if (!isUserLogged || !isPaidActive) {
      navigate(ERoutesPath.ROOT);
    }

    const handleInitialization = async () => {
      try {
        const simulateQuestions = await api.get<Array<TQuestion>>(
          `${END_POINTS.simulateQuestions}/${simulateId}`
        );

        setQuestions(Object.values(simulateQuestions.data));
        initializeSelectedQuestions(Object.values(simulateQuestions.data));

        const simulateResponse = await handleChangeStatus(
          ESimulateStatus.STARTED
        );

        setSimulateData(simulateResponse);
      } finally {
        setIsLoading(false);
      }
    };

    handleInitialization();
    isInitialized.current = true;

    return () => {
      if (simulateData?.status === ESimulateStatus.STARTED) {
        handleChangeStatus(ESimulateStatus.PAUSED);
      }
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <Flex
          width="100%"
          minHeight="500px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Flex>
      ) : (
        <PageTemplate
          title={simulateData?.name ?? "Simulado"}
          breadcrumbsItems={practiceTestBreadcrumb}
        >
          <Flex direction="column" gap={16}>
            <S.QuestionMenuContainer>
              <Flex direction="column" gap={16}>
                {questions &&
                  questions.map((question, key) => (
                    <Question
                      ref={(el) => (questionRefs.current[key + 1] = el)}
                      data={question as TQuestion}
                      key={question.id}
                      id={question.id}
                      number={key + 1}
                      data-id={question.id}
                      hideAction
                      previewsSelectedAnswered={question.selected_answered}
                      onSelectQuestion={(alternative, action) =>
                        handleSelectedQuestion(
                          key,
                          alternative,
                          question.id,
                          action
                        )
                      }
                    />
                  ))}
              </Flex>

              <QuestionMenu
                onSelectQuestion={scrollToQuestion}
                selectedQuestions={selectedQuestions}
              />
            </S.QuestionMenuContainer>
          </Flex>

          <Timer
            time={
              (simulateData?.time_to_finish ?? simulateData?.total_time ?? 1) *
              60
            }
            onPause={handlePauseSimulate}
            onFinished={handleFinishSimulate}
            enableFinish={isAllAlternativesFilled}
          />
        </PageTemplate>
      )}
    </>
  );
};
